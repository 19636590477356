<template>
  <div class="w-full">

    <div class="max-w-full mx-auto flex flex-col text-primary divide-y divide-gray">

      <div class="lb-navigation">

        <div class="order-2 text-left col-span-2">
          <div class="flex items-center lg:hidden">
            <UIMobileMenuButton />

            <UIMobileSearchButton />
          </div>
        </div>

        <Logo class="lb-nav-logo order-3 col-span-2"/>

        <LayoutNavigationMenu class="order-3" v-if="isShowMenu" />

        <div class="w-full order-4 flex sm:space-x-4 flex-1 justify-end col-span-2">
          <!-- Arama Formu -->
          <SearchInput class="hidden lg:flex" is-expandabled="true"/>

          <!-- Üst Sağ - Kullanıcı Menu Alanı-->
          <LayoutNavigationUserContentArea />
        </div>
      </div>

      <SearchInput class="w-full flex lg:hidden" v-if="isShowMobileSearch" is-expandabled="true"/>
    </div>
  </div>
</template>

<script setup>
const {isShowMenu, isShowMobileSearch} = useHelper()
</script>

<style lang="scss">
//.lb-navigation {
//  @apply relative grid lg:grid grid-cols-3 items-center justify-between w-full py-0.5 px-0.5 md:py-5 md:px-4 lg:gap-5;
//}
//
//.lb-nav-logo {
//  @apply justify-start;
//}
</style>